<template>
    <b-overlay id="overlay-background" spinner-variant="primary" :show="isLoading" variant="transparent" opacity="1.00"
        blur="0.5em" rounded="sm" class="w-100">
        <b-row>
            <b-col cols="12">
                <b-card>
                    <div v-if="refundDetail" class="d-flex justify-content-between align-items-center">
                        <div>
                            <p class="m-0">Refund Status:</p>
                            <b-badge :variant="refundDetail.has_paid ? 'success' : 'danger'">
                                <span class="m-0" style="font-size: 1rem;">{{ refundDetail.has_paid ? 'PAID' : 'UNPAID'
                                }}</span>
                            </b-badge>
                        </div>
                        <div>
                            <b-button
                                v-if="refundDetail.bank_account_name && refundDetail.bank_account_number && refundDetail.bank_name && !refundDetail.has_paid"
                                class="ml-1" variant="primary" @click="showPayout()">Pay</b-button>
                        </div>
                    </div>
                </b-card>
            </b-col>
            <b-col cols="12" md="6">
                <b-card>
                    <b-row>
                        <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
                            <span class="font-weight-bolder">No. Refund</span>
                        </b-col>
                        <b-col cols="8" class="mb-1">
                            <span> {{ refundDetail ? refundDetail.doc_number : '-' }} </span>
                        </b-col>
                        <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
                            <span class="font-weight-bolder">No. Invoice/Return</span>
                        </b-col>
                        <b-col cols="8" class="mb-1">
                            <router-link v-if="refundDetail" :to="{
                                name: refundDetail ? refundDetail.ref_type ===
                                    'App\\Models\\Order'
                                    ? 'order-detail' : 'return_sales_detail' : '-',
                                params: { id: refundDetail.refrence.id },
                            }">
                                <span class="font-weight-bolder">{{ refundDetail ? refundDetail.ref_type ===
                                    'App\\Models\\Order'
                                    ? refundDetail.refrence.transaction.no_invoice : refundDetail.refrence.number : '-'
                                }}</span>
                            </router-link>
                            <span v-else> - </span>
                        </b-col>
                        <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
                            <span class="font-weight-bolder">Status</span>
                        </b-col>
                        <b-col cols="8" class="mb-1">
                            <span> {{ refundDetail ? refundDetail.has_paid ? 'PAID' : 'UNPAID' : '-' }} </span>
                        </b-col>
                        <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
                            <span class="font-weight-bolder">Total</span>
                        </b-col>
                        <b-col cols="8" class="mb-1">
                            <span> {{ refundDetail ? formatCurrency(refundDetail.amount_refund) : '-' }} </span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" md="6">
                <b-card>
                    <b-row>
                        <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
                            <span class="font-weight-bolder">Email</span>
                        </b-col>
                        <b-col cols="8" class="mb-1">
                            <span> {{ refundDetail ? refundDetail.email ? refundDetail.email : '-' : '-' }} </span>
                        </b-col>
                        <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
                            <span class="font-weight-bolder">Nama Bank</span>
                        </b-col>
                        <b-col cols="8" class="mb-1">
                            <span> {{ refundDetail ? refundDetail.bank_name ? refundDetail.bank_name : '-' : '-' }} </span>
                        </b-col>
                        <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
                            <span class="font-weight-bolder">Nama Akun Bank</span>
                        </b-col>
                        <b-col cols="8" class="mb-1">
                            <span> {{ refundDetail ? refundDetail.bank_account_name ? refundDetail.bank_account_name : '-' :
                                '-' }} </span>
                        </b-col>
                        <b-col cols="4" class="text-right d-flex align-items-center justify-content-end mb-1">
                            <span class="font-weight-bolder">No. Rekening Bank</span>
                        </b-col>
                        <b-col cols="8" class="mb-1">
                            <span> {{ refundDetail ? refundDetail.bank_account_number ? refundDetail.bank_account_number :
                                '-' : '-' }} </span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col v-if="refundDetail" cols="12" md="6">
                <b-card title="Catatan:">
                    <b-row>
                        <b-col v-if="refundDetail.email === null" cols="12"
                            class="text-right d-flex align-items-start justify-content-start mb-1">
                            <span class="text-danger">Email tidak boleh kosong</span>
                        </b-col>
                        <b-col v-if="refundDetail.bank_name === null" cols="12"
                            class="text-right d-flex align-items-start justify-content-start mb-1">
                            <span class="text-danger">Nama Bank tidak boleh kosong</span>
                        </b-col>
                        <b-col v-if="refundDetail.bank_account_name === null" cols="12"
                            class="text-right d-flex align-items-start justify-content-start mb-1">
                            <span class="text-danger">Nama Akun Bank tidak boleh kosong</span>
                        </b-col>
                        <b-col v-if="refundDetail.bank_account_number === null" cols="12"
                            class="text-right d-flex align-items-start justify-content-start mb-1">
                            <span class="text-danger">No. Rekening Bank tidak boleh kosong</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-modal v-model="payoutModal" centered title="Refund" scrollable ok-title="Iya" cancel-title="Tidak"
            no-close-on-backdrop @ok="doPaid">
            <h5 class="mb-2">Apakah anda yakin sudah melakukan Pembayaran?</h5>
            <template #modal-footer="{ cancel, ok }">
                <b-button :disabled="confirmLoading" @click="cancel"> Tidak </b-button>
                <b-button :disabled="confirmLoading" variant="primary" @click="ok"><b-spinner v-if="confirmLoading" small
                        class="mr-1" /><span>Iya</span></b-button>
            </template>
        </b-modal>
    </b-overlay>
</template>
<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BCardTitle,
    BOverlay,
    BButton,
    BSpinner,
    BFormCheckbox,
    BBadge,
    BModal
} from 'bootstrap-vue'
import { onMounted, ref, watch } from '@vue/composition-api'
import { formatCurrency, title, formatDate } from '@/@core/utils/filter'
import { typeVoucherOptions } from '@core/utils/constant'
import service from '@/components/Table/service'
import refund from '@/router/routes/refund'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BTable,
        BCardTitle,
        BOverlay,
        BButton,
        BSpinner,
        BBadge,
        BModal,
    },
    setup(props, context) {
        const isLoading = ref(false)
        const refundDetail = ref(null)
        const getRefund = () => {
            console.log('get Refund data')
            isLoading.value = true
            service.show({ url: 'refund-datas', id: context.root.$route.params.id }).then(res => {
                refundDetail.value = res.data
            }).finally(() => {
                console.log('finish')
                isLoading.value = false
            })
        }
        onMounted(() => {
            getRefund()
        })

        const refundIdTemp = ref(null)
        const payoutModal = ref(false)
        const confirmLoading = ref(false)
        const doPaid = evt => {
            confirmLoading.value = true
            evt.preventDefault()
            service
                .store({ url: 'refund/pay', data: { id: refundIdTemp.value } })
                .then(res => {
                    payoutModal.value = false
                    getRefund()
                })
                .finally(() => {
                    confirmLoading.value = false
                })
        }
        const showPayout = () => {
            console.log('pay')
            payoutModal.value = true
            refundIdTemp.value = context.root.$route.params.id
        }

        return {
            refundDetail,
            isLoading,
            formatCurrency,
            payoutModal,
            confirmLoading,
            doPaid,
            showPayout
        }
    }
}
</script>
<style lang="scss">
.number_column_class {
    width: 50px;
}
</style>
  